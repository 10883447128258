import { useEffect, useRef, useState } from 'react';
import { Button, Icon, Modal, Tooltip, message } from 'ysd-pp';
import { useRequest } from '@/utils/request';
import { hasPermissions } from '@/utils/permissions';
import AddEditModal from './AddEditModal';
import { apiDeleteJobRules, apiGetAddedStationList, apiGetJobRulesList, apiGetSurveyTypeList } from '../../services';
import { returnArray } from '@/utils';
import EnhancedTable from '../EnhancedTable';

interface IJobRulesListProps {
    noTabs?: boolean;
}

export type TSelectOption = {
    label?: string;
    value?: number;
};

const JobRulesList = (props: IJobRulesListProps) => {
    const { noTabs } = props;
    const [visible, setVisible] = useState(false);
    const [addedStationList, setAddedStationList] = useState<TSelectOption[]>([]); // 获取已选的岗位
    const [surveyTypeList, setSurveyTypeList] = useState<TSelectOption[]>([]); /** 获取测评类型列表*/
    const [currentRecord, setCurrentRecord] = useState<any>({});
    const tableRef: any = useRef(null);

    const { runAsync: deleteJobRules } = useRequest(id => apiDeleteJobRules(id));
    const { runAsync: runGetAddedStationList } = useRequest(apiGetAddedStationList);
    const { runAsync: runGetSurveyTypeList } = useRequest(apiGetSurveyTypeList);

    const getOptionsList = () => {
        runGetAddedStationList().then(res => {
            const data = returnArray(res?.data)?.map(item => {
                return {
                    label: item,
                    value: item,
                };
            });
            setAddedStationList(data);
        });
        runGetSurveyTypeList().then(res => {
            const data = returnArray(res?.data)?.map(item => {
                return {
                    label: item.name,
                    value: item.code,
                };
            });
            setSurveyTypeList(data);
        });
    };

    useEffect(() => {
        getOptionsList();
    }, []);

    const handleDelete = async (record?: any) => {
        const confirm = Modal.confirm;
        // 二次弹窗确认
        confirm({
            title: '删除确认',
            className: 'opt-delete',
            content: (
                <>
                    <div className="desc">你确定要删除{record?.station}吗？</div>
                </>
            ),
            okText: '删除',
            cancelText: '取消',
            onOk: async () => {
                const res = await deleteJobRules(record?.id);
                if (res?.code === 200) {
                    message.success('删除成功');
                    handleReFreshTable();
                }
            },
        });
    };

    const columns = [
        {
            title: '岗位',
            dataIndex: 'station',
            isFilter: true,
            filterSearchType: 'radio',
            filters: addedStationList,
            render: (val: string) => {
                return val ? <Tooltip type="ellipsisTooltip" title={val} /> : '-';
            },
        },
        {
            title: '测评工具',
            dataIndex: 'survey_type',
            isFilter: true,
            filters: surveyTypeList,
            filterSearchType: 'radio',
            filterSearchName: 'type',
            render: (val: number) => <div>{surveyTypeList?.find(item => item?.value === val)?.label}</div>,
        },
        {
            width: 98,
            title: '操作',
            dataIndex: 'operations',
            fixed: 'right',
            render: (_, record) => {
                return (
                    <span>
                        {hasPermissions('stationUpdate') && (
                            <Tooltip type="tooltip" title="编辑">
                                <Icon
                                    type="edit"
                                    onClick={() => {
                                        setVisible(true);
                                        setCurrentRecord(record);
                                    }}
                                    className="edit-btn"
                                />
                            </Tooltip>
                        )}
                        {hasPermissions('stationDelete') && (
                            <Tooltip type="tooltip" title="删除">
                                <Icon
                                    type="delete"
                                    onClick={() => handleDelete(record)}
                                    className="delete-btn"
                                    disabled={record?.user_id === '13ac9112-bc4c-40f5-a4e4-3183b3d1424a'}
                                />
                            </Tooltip>
                        )}
                    </span>
                );
            },
        },
    ];

    const handleReFreshTable = () => {
        tableRef.current?.handleReFreshTable();
        getOptionsList();
    };

    return (
        <div>
            <EnhancedTable
                ref={tableRef}
                noTabs={noTabs}
                tableApi={apiGetJobRulesList}
                columns={columns as any}
                showSearch={true}
                operaButtons={
                    hasPermissions('stationCreate') && (
                        <div className="btn-wrap">
                            <Button
                                type="primary"
                                onClick={() => {
                                    setVisible(true);
                                }}
                            >
                                创建规则
                            </Button>
                        </div>
                    )
                }
            />
            <AddEditModal
                visible={visible}
                record={currentRecord}
                onCancel={() => {
                    setVisible(false);
                    setCurrentRecord({});
                }}
                refreshList={handleReFreshTable}
                surveyTypeList={surveyTypeList}
            />
        </div>
    );
};

export default JobRulesList;
